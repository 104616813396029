.gridItemContainer {
  display: grid;
  grid-template-rows: 1fr .3fr min-content;
  background-color: #2babc8;
  box-shadow: 8px 8px 10px rgba(19, 60, 70, 0.16);
  width: 100%;
  max-height: 300px;
  border: 2px solid #133c46;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.gridItemContainerWithHover {
  transition: all 0.2s;
}

.gridItemContainerWithHover:hover {
  background-color: #133c46;
}

.gridItemContainerWithHover:hover .gridItemIcon {
  transform: rotate(15deg);
}

.gridGameItemContainer {

}

.gridItemIconContainer {
}

.gridItemIcon {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  transition: all 0.2s;

  display: grid;
  place-items: center;
}

.gridItemTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  background-color: white;
  height: 100%;
}

.gridItemTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: #133c46;
  font-family: "BigNoodleTitling Regular" !important;
}

.gridItemSubtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: #ccc;
  margin-top: 0.5rem;
}

.gridItemTagsContainer {
  padding: 0.5rem;
  background: white;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  color: white;
  gap: 5px;
  height: 3rem;
}

.gridItemTag {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 0.75rem;
  background-color: #133c46;
  height: 2rem;
}

.gridItemTagCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.gridItemTagUnplugged {
  background-color: #2CE7C2;
}

.gridItemTagUnplugged .gridItemTagCircle {
  background-color: #2CE7C2;
}

.gridItemTagController {
  background-color: #E72CE6;
}

.gridItemTagController .gridItemTagCircle {
  background-color: #E72CE6;
}

.gridItemTagCog {
  background-color: #FFB300;
}

.gridItemTagCog .gridItemTagCircle {
  background-color: #FFB300;
}

.gridItemTagMarty {
  background-color: #2BABC8;
}

.gridItemTagMarty .gridItemTagCircle {
  background-color: #2BABC8;
}


.gridItemTagBlocksJr {
  background-color: #FFB300;
}

.gridItemTagBlocksJr .gridItemTagCircle {
  background-color: #FFB300;
}

.gridItemTagBlocks {
  background-color: #0012FF;
}

.gridItemTagBlocks .gridItemTagCircle {
  background-color: #0012FF;
}

.gridItemTagSensorDashboard {
  background-color: #09FF00;
}

.gridItemTagSensorDashboard .gridItemTagCircle {
  background-color: #09FF00;
}

.comingSoonOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  z-index: 1;
  cursor: not-allowed;
}



@media only screen and (min-width: 1600px) {
.gridItemTextContainer {}
.gridItemTitle {}
.gridItemSubtitle {}
}

@media only screen and (max-width: 1600px) {
.gridItemTextContainer {
  padding: .5rem .25rem;
}
.gridItemTitle {
  font-size: 2rem;
}
.gridItemSubtitle {
  font-size: 1.25rem;
}
}

@media only screen and (max-width: 1400px) {
.gridItemTextContainer {}
.gridItemTitle {}
.gridItemSubtitle {}
}

@media only screen and (max-width: 1200px) {
.gridItemTextContainer {}
.gridItemTitle {}
.gridItemSubtitle {}
}

@media only screen and (max-width: 1000px) {
.gridItemTextContainer {}
.gridItemTitle {}
.gridItemSubtitle {}
}

@media only screen and (max-width: 800px) {
.gridItemTextContainer {}
.gridItemTitle {}
.gridItemSubtitle {}
}

@media only screen and (max-width: 660px) {
.gridItemTextContainer {}
.gridItemTitle {}
.gridItemSubtitle {}
}
