.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 2rem;
  position: relative;
}

.filterByContainer {
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  gap: 1rem;
}

@media only screen and (min-width: 1600px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media only screen and (max-width: 1600px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media only screen and (max-width: 1400px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media only screen and (max-width: 1200px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media only screen and (max-width: 1000px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media only screen and (max-width: 800px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  }
}

@media only screen and (max-width: 660px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
