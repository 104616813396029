.minimizedMainContainer,
.mainContainer {
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 25rem 1fr;
}

.mainHeaderContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 5rem;
  color: #133c46;
  letter-spacing: 0.1px;
  font-family: "BigNoodleTitling Regular";
  grid-column: 1 / 3;
  padding-right: 3rem;
}

.contentToggles {
  justify-self: end;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
}

.mainHeaderContainer .title {
  padding-left: 1rem;
}

.minimizedMainContainer {
  grid-template-columns: 10rem 1fr;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mainContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mainContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.mainNavigationContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.mainMainContentContainer {
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
  position: relative;
  background-color: var(--colour-quinary);
  background-image: url('/src/assets/screens_background.svg');
  background-size: cover;
  background-position: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mainNavigationContainer::-webkit-scrollbar,
.mainMainContentContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mainNavigationContainer,
.mainMainContentContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.betaSignIconContainer {
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 8vw;
  height: 4vw;
  margin: 1rem;
  border-radius: 1rem;
  opacity: 0.7;
  cursor: pointer;
  z-index: 100;
}

.betaSignIconContainer:hover {
  opacity: 1;
}
