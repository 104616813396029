.navButtonOuterContainer {
  padding: 1rem;
  text-align: center;
  position: relative;
  border: none;
  cursor: pointer;
  background-image: linear-gradient(to right, #133C46 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.2s ease-in-out;
}

.navButtonOuterContainerWithHover:hover {
  background-position: left bottom;
} 

.navButtonOuterContainerWithHover:hover .navButtonIcon {
  transform: rotate(15deg);
}

.navButtonContainer {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 2rem;
  align-items: center;
  justify-items: left;
  cursor: pointer;
  width: 100%;
  padding-left: 2rem;
}

.navButtonImageContainer {
  background-color: white;
  /* border: 1px solid var(--colour-BLUE_DARK_2); */
  display: grid;
  justify-items: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.navButtonSelected {
  background-color: #133C46;
}

.navButtonIcon {
  width: 3rem;
  height: 3rem;
  transition: transform 0.2s ease-in-out;
}

.navButtonIconWithHover:hover {
  transform: rotate(15deg);
}

.navButtonTitleResize,
.navButtonTitle {
  font-size: 2rem;
  text-align: left;
  color: white;
  text-transform: uppercase;
}

@media screen and (min-width: 1400px) {
  .navButtonTitleResize {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 1400px) {
  .navButtonTitleResize {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1200px) {
  .navButtonTitleResize {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1000px) {
  .navButtonTitleResize {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 800px) {
  .navButtonTitleResize {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 600px) {
  .navButtonTitleResize {
    font-size: 1.5rem;
  }
}

/* Minimum aspect ratio */
@media (min-aspect-ratio: 8/4) and (max-width: 800px) {
  .navButtonImageContainer svg {
    max-width: 75%;
  }
}

@media (min-aspect-ratio: 8/4) and (max-height: 800px) {
  .navButtonImageContainer svg {
    max-width: 75%;
  }
}
