.dropdownContent {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* padding: 10px; */
}

.dropdownItem {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: #B8EBF8;
}

.dropdownSeparator {
    height: 1px;
    background-color: #e0e0e0;
}

.checkboxItem {
    display: grid;
    grid-template-columns: 20px 1fr;
    padding: 8px 12px;
    cursor: pointer;
    outline: none;
    user-select: none;
}

.checkboxLabel {
    color: #133C46;
    margin: 0;
    padding: 0;
    /* font-size: 1rem; */
    grid-column: 2;
}

.checkboxItemWithHover:hover {
    background-color: #B8EBF8;
}

.itemIndicator {

}


.dropdownTrigger {
    background-color: #FFFFFF;
    border: 2px solid #133C46;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    padding: 4px 6px;
    cursor: pointer;
    outline: none;
    user-select: none;

    display: grid;
    grid-template-columns: minmax(60px, 150px) 20px;
    place-items: center;
}

.filterBySpan {
    font-family: "BigNoodleTitling Regular";
    font-size: 1.5rem;
    color: #133C46;
    justify-self: start;
}
