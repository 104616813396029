.bugReportModalContainer {
  display: grid;
  grid-template-areas: "bug-info" "bug-form" "bug-buttons";
  grid-template-columns: 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
  max-width: 90vw;
  max-height: 90vh;
}

.bugReportModalInfo {
  grid-area: bug-info;
  width: 50%;
  justify-self: center;
  text-align: center;
  color: var(--colour-GREY);
}

.bugReportModalForm {
  grid-area: bug-form;
}

.bugReportModalTextInput,
.bugReportModalEmailInput {
  width: 100%;
  padding: 1rem;
  font-size: 2rem;
  border: 1px solid var(--colour-MAIN_BLUE);
  border-radius: 5px;
  outline: none;
  font-size: 1.6rem;
  resize: vertical;
  max-height: 50vh;
}

.bugReportModalInfoStrong {
  font-weight: bold !important;
  color: black !important;
  text-decoration: underline;
}

.bugReportModalEmailInput {
  width: auto;
}

.bugReportModalEmailLabel {
  display: block;
  font-size: 1.2rem;
  font-style: italic;
}

.bugReportModalTextInput:focus {
  outline: none;
}

.bugReportModalButtons {
  grid-area: bug-buttons;
  display: grid;
  grid-auto-flow: column;
  column-gap: 2rem;
}
