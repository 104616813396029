
.verification-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80vw;
  height: 60vh;
  background-color: white;
  align-items: end;
  padding: 1rem;
}

.verification-modal-martys-container {
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
}

.verification-modal-marty-name-row-container {
  display: grid;
  grid-template-columns: 1fr .06fr .1fr;
  column-gap: 2rem;
  align-items: center;
  border-bottom: 1px solid var(--colour-GREY_4);
  padding-bottom: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  width: 100%;
}

.verification-modal-marty-signal-container {
}


.verification-modal-marty-name {
  align-self: end;
}

.verification-modal-martys-back-hint {
  padding-top: 1rem;
  font-size: 1.2rem;
  color: var(--colour-GREY_3); 
}

.verification-modal-led-row-container {
  display: grid;
  grid-auto-flow: column;
  align-items: end;
  column-gap: 5rem;
}

.verification-modal-bottom-btns-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr .5fr 1fr;
  width: 100%;
}