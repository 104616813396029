.navButtonsContainer {
  display: grid;
  row-gap: 2.2rem;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navButtonsContainer::-webkit-scrollbar {
  display: none;
}

.navButtonsRowContainer {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
}