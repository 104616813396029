@keyframes comeInFromBottom {
  0% {
    transform: translateY(100%);
  }
  80% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(1%);
  }
}

@keyframes leaveToBottom {
  0% {
    transform: translateY(1%);
  }
  20% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(100%);
  }
}

.modalBody {
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
}

.modal {
  /* grayish */
  background: var(--colour-BLUE_LIGHT_3);
  border-radius: 15px;
  padding: 15px;
  max-height: 90vh;
  overflow: scroll;
  position: relative;
}

.modalClosing {
  animation: leaveToBottom 0.7s ease-in;
}

.modalOpening {
  animation: comeInFromBottom 0.7s ease-in;
}

.modalLogoContainer {
  display: grid;
  justify-items: center;
  margin-bottom: 1rem;
}

.modalOverlay {
  position: absolute;
  bottom: 1%;
  right: 1%;
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* background-color: rgba(0, 0, 0, 0.7); */
  z-index: 1000;
}

.title {
  font-size: 2.5rem;
  font-family: "BigNoodleTitling Regular";
  text-align: center;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    background: none;
    border: none;
    font-size: 15px;
    cursor: pointer;
    color: var(--grey-4);
    transition: color 0.3s;
}