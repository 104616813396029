.robotButtonContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid #133c46;
  border-radius: 5px;
}

.selected {
  /* border: 2px solid var(--colour-MAIN_BLUE); */
}

.robotButtonContainerInner {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr min-content min-content;
  grid-template-columns: 0.45fr 0.55fr;

  grid-template-areas:
    "robotButtonIconContainer robotButtonIconContainer"
    "signalContainer batteryContainer"
    "robotButtonNameContainer robotButtonNameContainer";

  row-gap: 0.2rem;
}

.robotButtonIconContainer {
  grid-area: robotButtonIconContainer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2rem; */
}

.robotButtonIcon {
  width: 4.3rem;
  height: 4.3rem;
  /* padding-bottom: 2px; */
  padding-top: 4px;
}

.robotButtonNameContainer {
  grid-area: robotButtonNameContainer;
  background-color: #89c761;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
}

.robotButtonName {
  color: white;
  font-size: 1.1rem;
}

.batteryContainer {
  align-self: center;
  grid-area: batteryContainer;
}

.batteryIcon {
  width: 4rem;
}

.signalContainer {
  justify-self: center;
  grid-area: signalContainer;
  transform: none;
  width: 2.4rem;
  display: grid;
}

.signalIcon {
}

.trashContainer {
  position: absolute;
  top: -1.2rem;
  left: -1.2rem;

  background-color: var(--colour-MAIN_BLUE);

  border-radius: 50%;
  width: 3rem;
  height: 3rem;

  display: flex;
  justify-content: center;
  cursor: pointer;
}
