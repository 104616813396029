.navMenuContainer {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  display: grid;
  grid-template-areas: "toggle" "connect-button" "nav-buttons" "help-section";
  grid-template-rows: min-content min-content 1fr min-content;
  padding-top: 2rem;

  /* border-right: 2px solid var(--colour-BLUE_DARK_1); */
  background-color: var(--colour-MAIN_BLUE);
  transition: width 0.3s ease, padding 0.3s ease;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.navMenuContainer::-webkit-scrollbar,
.navMenuNavButtonsContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.navMenuContainer,
.navMenuNavButtonsContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.navMenuConnectButtonContainer {
  grid-area: connect-button;
  align-self: end;
  padding: 1rem;
}

.navMenuNavButtonsContainer {
  grid-area: nav-buttons;
  padding-top: 2rem;
  overflow-y: scroll;
}

.navMenuHelpSectionContainer {
  grid-area: help-section;
  padding: 1rem;
}

.minimizedNav {
  width: 10rem;
}

.toggle {
  grid-area: toggle;
  display: flex;
  justify-content: right;
  cursor: pointer;
}