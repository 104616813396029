:root {
  --colour-primary: rgb(55, 171, 200);
  --colour-secondary: rgb(244, 218, 97);
  --colour-tertiary: rgb(0, 213, 146);
  --colour-quaternary: #0a2d2d;
  --colour-quinary: rgb(248 244 230);
  --colour-BLACK: rgb(38, 38, 38);
  --colour-BLACK_075: rgba(38, 38, 38, 0.75);
  --colour-BLACK_05: rgba(38, 38, 38, 0.5);
  --colour-BLACK_025: rgba(0, 0, 0, 0.25);
  --colour-GREY: rgba(81, 81, 81, 0.5);
  --colour-GREY_2: #f5f5f5;
  --colour-GREY_3: rgb(143, 142, 148);
  --colour-GREY_4: rgb(223, 223, 223);
  --colour-GREY_5: rgb(190 190 190);
  --colour-HINT_GREY: rgba(125, 125, 125, 0.5);
  --colour-MAIN_BLUE: rgb(55, 171, 200);
  --colour-LIGHT_BLUE: rgb(48, 197, 235);
  --colour-AQUA_BLUE_025: rgba(55, 171, 200, 0.25);
  --colour-AQUA_BLUE_05: rgba(55, 171, 200, 0.5);
  --colour-BLUE_LIGHT_1: rgb(214, 243, 246);
  --colour-BLUE_LIGHT_2: rgb(164, 218, 229);
  --colour-BLUE_LIGHT_3: rgb(161, 213, 227);
  --colour-BLUE_DARK_1: rgb(59 168 196);
  --colour-BLUE_DARK_2: rgb(46 128 149);
  --colour-BLUE_DARK_3: rgb(0, 128, 84);
  --colour-PALE_BLUE: #94cddc;
  --colour-WHITE: rgb(245, 245, 245);
  --colour-TRUE_WHITE: rgb(255, 255, 255);
  --colour-PALE_WHITE: rgba(245, 245, 245, 0.5);
  --colour-GREEN: rgb(0, 213, 146);
  --colour-RED: rgb(237, 82, 82);
  --colour-SAND_YELLOW: rgb(244, 218, 97);
  --colour-SAND_YELLOW_25: rgba(244, 218, 97, 0.25);
  --colour-SAND_YELLOW_50: rgba(244, 218, 97, 0.5);
  --colour-BLUE_LED: rgba(0, 0, 128, 0.5);
  --colour-GREEN_LED: rgba(0, 128, 0, 0.5);
  --colour-PINK_LED: rgba(128, 0, 128, 0.5);
  --colour-SCREENFREE_PURPLE: rgb(118, 53, 151);
  --colour-SCREENFREE_GREEN: rgb(3, 125, 60);
  --colour-SCREENFREE_YELLOW: rgb(250, 217, 5);
  --colour-SCREENFREE_RED: rgb(91, 20, 19);
  --colour-SCREENFREE_BLUE: rgb(0, 170, 255);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


html, body, #root {height: 100%;}

html {
  /* This defines what 1rem is: 1rem = 10px; 10/16 = 62.5 */
  font-size: 62.5%;
  overflow: hidden;
}

body {
  box-sizing: border-box;
  font-size: 1.6rem;
}

p,
div {
  font-family: "Lato Regular";
}

@media screen and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  html {
      /* font-size: 65% !important; */
  }
}


@media only screen and (max-width: 1000px) {
  html {
      font-size: 58% !important;
  }
}

@media only screen and (max-width: 800px) {
  html {
      font-size: 45% !important;
  }
}

@media only screen and (max-width: 600px) {
  html {
      font-size: 30% !important;
  }
}

@media only screen and (max-width: 290px) {
  html {
      font-size: 20% !important;
  }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
}

* {
  -webkit-tap-highlight-color: transparent;
}
