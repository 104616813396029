.mainWindow {
    text-align: center;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.contentWindow {
    flex: auto;
    height: 100%;
}