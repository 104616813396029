
.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Arial, sans-serif;
  position: relative;
  column-gap: 0.5rem;

  user-select: none;
}

.toggleContainer img {
  cursor: pointer;
}

.toggleIconContainer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  column-gap: 0.5rem;
}

.toggleIcon {
  width: 2.5rem;
  height: 2.5rem;
}

.label {
  font-size: 1.2rem;
  color: var(--colour-BLUE_DARK_2);
}

.robotIcon {
  width: 2.4rem;
  height: 2.4rem;
}

.robotName {
  font-size: 0.8rem;
  color: var(--colour-BLUE_DARK_2);
}