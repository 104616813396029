.betaSign {
  display: none;
  align-items: center;
  justify-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 8vw;
  height: 4vw;
  margin: 1rem;
  border-radius: 1rem;
  opacity: 0.7;
  cursor: pointer;
  z-index: 100;
}

.beta-sign-icon-container:hover {
  opacity: 1;
}
